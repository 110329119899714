.slider-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}

.slider-content-wrapper {
  --duration: 10s;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  animation: slide var(--duration) linear infinite;
}

.slider-content-wrapper > div {
  display: inline-block;
  width: max-content;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1123px) {
  .slider-content-wrapper {
    animation: none;
  }
  .slider-content-wrapper .duplicate {
    display: none;
  }
}
